import { useState, useRef, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import Form from 'react-bootstrap/Form';

function Overlayy() {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [isChecked, setIsChecked] = useState(
    () => JSON.parse(localStorage.getItem('activateAll')) || false
  );

  const ref = useRef(null);

  useEffect(() => {
    localStorage.setItem('activateAll', JSON.stringify(isChecked));
  }, [isChecked]);

  const handleClick = (event) => {
    setShow((prev) => !prev);
    setTarget(event.target);
  };

  const handleToggle = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    localStorage.setItem('activateAll', JSON.stringify(newValue)); // Ensure storage updates immediately
	window.location.reload(); // Reload the page

  };

  return (
    <div ref={ref} className="mnu">
      <Button variant="" onClick={handleClick}>Providers</Button>

      <Overlay
        show={show}
        target={target}
        placement="bottom"
        container={ref}
        containerPadding={20}
      >
        <Popover id="popover-contained">
          <Popover.Header as="h3">Provider activation</Popover.Header>
          <Popover.Body>
            <strong>
              As some of the resources provided in APSearch may be problematic for some users for political reasons, they are only displayed after active selection.
            </strong> 
            <br />Click here to view additional providers/sources.
            <Form>
              <Form.Check 
                type="switch"
                id="custom-switch"
                label="Activate additional providers"
                checked={isChecked}
                onChange={handleToggle}
              />
            </Form> 
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
}

export default Overlayy;
