import React, { useEffect } from "react";

import ElasticsearchAPIConnector from "@elastic/search-ui-elasticsearch-connector";
import { useTranslation } from "react-i18next";
import DetailResultView from "./CustomResultView"

import {
	ErrorBoundary,
	SearchProvider,
	Results,
  	WithSearch
} from "@elastic/react-search-ui";
import { useParams } from 'react-router-dom';


import {
	getConfig,
} from "./config/config-helper";
export default function App() {
	const {apsid } = useParams();
	

	const { searchKey, endpointBase, engineName } = getConfig();

	const connector = new ElasticsearchAPIConnector({
		host: endpointBase,
		index: engineName,
		apiKey: searchKey
	});
	const config = {
	  apiConnector: connector,
	  alwaysSearchOnInitialLoad: true,
	  	resultsPerPage: 1,
	  		initialState: {
	  			resultsPerPage: 1
	  	},
	  searchQuery: {
	    filters: [
	      {
	        field: "apsId",
	        values: [apsid],
	        type: "all",
	      },
	    ],
	    result_fields: {
	      "*": { raw: {} },
	    },
	  },
	};

	const { i18n } = useTranslation();
	useEffect(() => {
		document.dir = i18n.dir();
		document.documentElement.lang = i18n.language;
		document.title = i18n.t("global.app.name");
		console.info(document.dir);
	}, [i18n, i18n.language]); 
	return (

		<SearchProvider config={config}  >

			<WithSearch mapContextToProps={({ wasSearched }) => ({ wasSearched })}>
				{({ wasSearched }) => {
					return (
						<ErrorBoundary>
						<Results resultView={DetailResultView} className="light"/>
						</ErrorBoundary>
					);
				}}


			</WithSearch>
		</SearchProvider>
	);
}
