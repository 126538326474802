import React from "react";
import { useTranslation } from "react-i18next";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import { Link } from "react-router-dom";
import logoImg from './logo.svg';
import Overlayy from './Overlayy'
import { supportedLanguages } from "../config/i18n";
import { languageCodeOnly } from "../services/i18n";
function Navbar({ onLanguageChange }) {

	
	const { i18n } = useTranslation();
	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
	}

	return (
		<nav className="navbar"	>

				<div className="navbar-item">
				<a href="/">	<img  className="agyaLogo" src={logoImg} alt="APSeearch Logo" /></a>
				</div>

				<div className="navbar-item">
					<a href="/" title="AGYA Phonogram Search">
						<div className="navbar-logo">
							<h1>{i18n.t('global.app.name')}</h1>

						</div>

					</a>
				</div>
				

				
				
				<div className="navbar-item last">
								<select className="form-select lang-select" 
														value={languageCodeOnly(i18n.language)}
														onChange={(e) => changeLanguage(e.target.value)}>
														{supportedLanguages.map((lang) => (
															<option key={lang.code} value={lang.code}>
																{lang.name}
															</option>
														))}
													</select>
								</div>
								
				<div className="navbar-item last">
									<Overlayy />
									<Link className="mnu" to="/imprint">Imprint</Link>
									<Link className="mnu"  to="/about">About</Link>
									<Link className="mnu" to="/privacy">Privacy</Link>
								</div>
		</nav>
	);
}

export default Navbar;