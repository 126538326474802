import React from 'react';

function About() {
	return (
<div class="container">
		    <h1>About APSearch</h1>

			<div class="row">
			    <div class="col-lg-12 col-12">
				<img className="schiffer" src="SchifferSiwa.webp" alt="Unknown musicians recorded with a phonograph by Brigitte Schiffer and Hans Hickmann, Siwa Oasis, Egypt, 1933. Photo: Hans Hickmann, with kind permission Rolf Hickmann."/>
				<p><i>Unknown musicians recorded with a phonograph by Brigitte Schiffer and Hans Hickmann, Siwa Oasis, Egypt, 1933.</i>
				 <br/><span className="minuscule">Photo: Hans Hickmann, with kind permission Rolf Hickmann.</span></p>
				</div>
				
			</div>
				
		    <div class="row">
		        <div class="col-lg-4 col-12">
		            <h3>Discovering Historical Arab Sound Heritage</h3>
		            <p>
		                APSearch (Arab Phonogram Search) is a digital platform that collects and organizes information about 
		                sound recordings, making them searchable through a user-friendly interface. The platform focuses on 
		                enhancing access to historical sound recordings of music and speech from the Arab world. Our corpus 
		                encompasses both scholarly and commercial audio recordings in Arabic or from the Arab world dating back 
		                to the late 19th century. 
						</p>
							            <p>
		                While commercial recordings are included up to the end of the shellac era (around 1940), scholarly 
		                recordings from all periods, including contemporary collections, are listed in the platform. APSearch 
		                does not host the recordings themselves but provides detailed, searchable metadata from various archives, 
		                libraries, and private collections. 
						</p>
							            <p>
		                Ideally, each search result aims to connect users directly to the original audio in its source repository 
		                or archive. If this is not possible, for example because the archive or collection in question does not 
		                make digitised recordings available in open access, hits in APSearch will lead to a landing page for the 
		                institution in question and the corresponding catalogue, or to a contact option.
		            </p>
		        </div>
		        <div class="col-lg-4 col-12">
		            <h3>Research Background</h3>
		            <p>
		                Sound recordings represent invaluable primary sources for historical research on the Arab world, 
		                particularly in musicology, anthropology, and linguistics. Dating back to the late 19th century, these 
		                recordings preserve endangered or historically significant cultural heritage, including regional language 
		                varieties and musical traditions that might otherwise be lost to time. They serve as crucial documents 
		                of media history while illuminating the historiological and epistemological contexts of their creation.
		            </p>
		            <p>
		                However, the historical context of these recordings requires critical consideration. Many were created 
		                under problematic conditions, reflecting power imbalances and hierarchical relationships within colonial 
		                institutional frameworks. Consequently, the majority of Arab world audio archives collected between 1890 
		                and the end of the twentieth century are housed in institutions in the so-called Global North. This 
		                presents significant economic and political barriers to access, particularly for scholars and the general 
		                public from Arab countries. 
						</p>
							            <p>
		                Beyond these implications, the recordings aroused the interest of both indigenous and Western 
		                communities. The intricate history of the production, collection, and preservation of these early sound 
		                recordings can now be seen as a shared heritage. APSearch addresses the challenges and responsibilities 
		                involved by providing a multilingual web service that improves the discoverability of these important 
		                cultural resources.
		            </p>
		        </div>
		        <div class="col-lg-4 col-12">
		            <h3>Technical Features</h3>
		            <p>
		                The platform aggregates metadata in Arabic, English, French, German, and Spanish, enabling broader 
		                access and searchability across language barriers. Our faceted search system allows users to filter 
		                and discover recordings based on multiple criteria, making it easier to locate specific historical 
		                materials.
		            </p>
					<h3>Funding</h3>
		            <p>
		                The Arab-German Young Academy of Sciences and Humanities (AGYA), operating under funding from the 
		                German Federal Ministry of Education and Research (BMBF), supports APSearch in conjunction with the 
		                Berlin Senate.
		            </p>
								
					<h3>Take our UCD Survey</h3>
		            <p>
		                Help us to improve the APSearch. The goal of APSearch is to implement a global search engine for historical audio recordings from Arab regions or in Arabic language. We take your feedback into account for the next development iteration.
						<br/><a href="https://survey.apsearch.org/apsearch">Take the ananonymous 3-minute survey</a> 
		            </p>

					
		        </div>
		    </div>
		</div>

	);
}

export default About;